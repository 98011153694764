export const gwScheduleTimeData = [
  {
    time: [
      [
        {
          title: "10:30",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "11:30",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "13:00",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "14:00",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "15:00",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "16:00",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "17:00",
          color: "#222222",
          bold: true,
        },
      ],
    ],
  },
];
export const gwScheduleData = [
  {
    title: "화",
    time: [
      [
        {
          title: "4 신나는 모험",
          color: "#ea5520",
          bold: false,
        },
        {
          title: "(24-26개월)",
          color: "#ea5520",
          bold: false,
        },
      ],
      [],
      [],
      [],
      [],
      [
        {
          title: "3 놀기 대장",
          color: "#cf0047",
          bold: false,
        },
        {
          title: "(18-20개월)",
          color: "#cf0047",
          bold: false,
        },
      ],

      [
        {
          title: "5 음악극2",
          color: "#f39800",
          bold: false,
        },
        {
          title: "(33-35개월)",
          color: "#f39800",
          bold: false,
        },
      ],
    ],
  },
  {
    title: "수",
    time: [
      [
        {
          title: "3 오늘도 즐거워",
          color: "#cf0047",
          bold: false,
        },
        {
          title: "(21-23개월)",
          color: "#cf0047",
          bold: false,
        },
      ],
      [],
      [
        {
          title: "2 세상 만나기",
          color: "#007bc7",
          bold: false,
        },
        {
          title: "(12-14개월)",
          color: "#007bc7",
          bold: false,
        },
      ],
      [
        {
          title: "3 놀기 대장",
          color: "#cf0047",
          bold: false,
        },
        {
          title: "(18-20개월)",
          color: "#cf0047",
          bold: false,
        },
      ],

      [
        {
          title: "4 테마극장",
          color: "#ea5520",
          bold: false,
        },
        {
          title: "(27-29개월)",
          color: "#ea5520",
          bold: false,
        },
      ],
      [],
      [],
    ],
  },
  {
    title: "목",
    time: [
      [
        {
          title: "1 천재 음악가",
          color: "#439c2b",
          bold: false,
        },
        {
          title: "(9-11개월)",
          color: "#439c2b",
          bold: false,
        },
      ],
      [],
      [],
      [],
      [
        {
          title: "4 테마극장",
          color: "#ea5520",
          bold: false,
        },
        {
          title: "(27-29개월)",
          color: "#ea5520",
          bold: false,
        },
      ],
      [
        {
          title: "4 신나는 모험",
          color: "#ea5520",
          bold: false,
        },
        {
          title: "(24-26개월)",
          color: "#ea5520",
          bold: false,
        },
      ],
      [
        {
          title: "3 오늘도 즐거워",
          color: "#cf0047",
          bold: false,
        },
        {
          title: "(21~23개월)",
          color: "#cf0047",
          bold: false,
        },
      ],
    ],
  },
  {
    title: "금",
    time: [
      [],
      [],

      [
        {
          title: "2 나도나도",
          color: "#007bc7",
          bold: false,
        },
        {
          title: "(15-17개월)",
          color: "#007bc7",
          bold: false,
        },
      ],
      [
        {
          title: "4 신나는 모험",
          color: "#ea5520",
          bold: false,
        },
        {
          title: "(24-26개월)",
          color: "#ea5520",
          bold: false,
        },
      ],
      [],
      [],
      [],
    ],
  },
  {
    title: "토",
    time: [
      [
        {
          title: "10:15",
          color: "#222222",
          bold: true,
        },
        {
          title: "5 음악극3",
          color: "#f39800",
          bold: false,
        },
        {
          title: "(36-38개월)",
          color: "#f39800",
          bold: false,
        },
      ],
      [
        {
          title: "11:15",

          color: "#222222",
          bold: true,
        },
        {
          title: "5 음악극1",
          color: "#f39800",
          bold: false,
        },
        {
          title: "(30-32개월)",
          color: "#f39800",
          bold: false,
        },
      ],
      [
        {
          title: "12:15",
          color: "#222222",
          bold: true,
        },
        {
          title: "4 테마극장",
          color: "#ea5520",
          bold: false,
        },
        {
          title: "(27-29개월)",
          color: "#ea5520",
          bold: false,
        },
      ],
      [],

      [
        {
          title: "15:00",
          color: "#222222",
          bold: true,
        },

        {
          title: "2 세상 만나기",
          color: "#007bc7",
          bold: false,
        },
        {
          title: "(12-14개월)",
          color: "#007bc7",
          bold: false,
        },
      ],
      [],
      [],
    ],
  },
];
