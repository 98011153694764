import BannerImage from "../../Componets/Banner/BannerImage";
import Footer from "../../Componets/Footer";
import { Default, Desktop, Mobile, Tablet } from "../../Device";
import PHeader from "./PHeader";
import {
  Back,
  Body,
  WWUContactEmail,
  WWUContactEmailTitle,
  WWUContactSection,
  WWUContactTextInfo,
  WWUContactTextInfo1,
  WWUContactTextSection,
  WWUDescription,
  WWUImage,
  WWUSection,
  WWUTitleView,
} from "./playsongsts";

export function WorkWithUs() {
  return (
    <Back>
      <PHeader />
      <Body>
        <WorkWithUsView />
        <Footer flagheight={true} />
      </Body>
    </Back>
  );
}

function WorkWithUsView() {
  return (
    <>
      <Mobile>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/contactus/mobile/banner.png"
          }
        />
      </Mobile>
      <Tablet>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/contactus/tablet/banner.png"
          }
        />
      </Tablet>
      <Desktop>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/contactus/banner.png"
          }
        />
      </Desktop>

      <Default>
        <DefaultSection />
      </Default>
      <Mobile>
        <MobileSection />
      </Mobile>
    </>
  );
}

function MobileSection() {
  return (
    <WWUSection>
      <WWUImage>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/mobile/mo-work%403x.png"
          alt="work-pc"
          width={"100%"}
        />
      </WWUImage>
      <WWUTitleView>Music in Play, Play in Music!</WWUTitleView>
      <WWUDescription>
        전문가 그룹이 만들고 검증한 프로그램으로 음악과 놀이를 통한 다양한
        경험으로 유아들의 프리미엄 통합교육을 자랑하는 플레이송스에서 아이들을
        좋아하고 음악수업을 통해 즐거운 에너지를 주고받을 열정있는 선생님을
        모집합니다.
      </WWUDescription>

      <WWUContactSection>
        <WWUContactTextSection>
          <span>[우대사항]</span>
          <WWUContactTextInfo className="text_wrap">
            -유아교육관련 전공 {"\n"}-음악관련 전공
          </WWUContactTextInfo>
          <WWUContactTextInfo1 className="text_wrap">
            -영어 가능자
            {"\n"}-피아노 반주 가능자
          </WWUContactTextInfo1>
          <WWUContactEmailTitle>지원 문의나 이력서 제출</WWUContactEmailTitle>
          <WWUContactEmail>contact_playsongs@playsongs.co.kr</WWUContactEmail>
        </WWUContactTextSection>
      </WWUContactSection>
    </WWUSection>
  );
}

function DefaultSection() {
  return (
    <WWUSection>
      <WWUTitleView>Music in Play, Play in Music!</WWUTitleView>
      <WWUDescription>
        전문가 그룹이 만들고 검증한 프로그램으로 음악과 놀이를 통한 다양한
        경험으로 유아들의 프리미엄 통합교육을 자랑하는 플레이송스에서 아이들을
        좋아하고 음악수업을 통해 즐거운 에너지를 주고받을 열정있는 선생님을
        모집합니다.
      </WWUDescription>

      <WWUContactSection>
        <WWUContactTextSection>
          <span>[우대사항]</span>
          <WWUContactTextInfo className="text_wrap">
            -유아교육관련 전공 {"\n"}-음악관련 전공
          </WWUContactTextInfo>
          <WWUContactTextInfo1 className="text_wrap">
            -영어 가능자
            {"\n"}-피아노 반주 가능자
          </WWUContactTextInfo1>
          <WWUContactEmailTitle>지원 문의나 이력서 제출</WWUContactEmailTitle>
          <WWUContactEmail>contact_playsongs@playsongs.co.kr</WWUContactEmail>
        </WWUContactTextSection>
        <WWUImage>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/pc/pc-work%403x.png"
            alt="work-pc"
            width={"100%"}
          />
        </WWUImage>
      </WWUContactSection>
    </WWUSection>
  );
}
