import { useState } from "react";
import BannerImage from "../../Componets/Banner/BannerImage";
import { handleOpenNewTab } from "../../Componets/common";
import Footer from "../../Componets/Footer";
import { Default, Desktop, Mobile, Tablet, TabletMobile } from "../../Device";
import PHeader from "./PHeader";
import {
  Back,
  Body,
  ActionButton,
  ButtonView,
  ContactUsInfoSection,
  ContactUsInfoSubScript,
  ContactUsInfoTitle,
  ContactUsInfoTitleIcon,
  ContactUsInfoZone,
  ContactUsSlider,
  ScheduleInfoView,
  SelectSectionView,
} from "./playsongsts";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ContactusData, ContactusDataG } from "../../Json/contactus";

export interface IContactUsProps {
  isAp: boolean;
}

export default function ContactUs({ isAp }: IContactUsProps) {
  return (
    <Back>
      <PHeader />
      <Body>
        <ContactUsView isAp={isAp} />
        <Footer flagheight={true} />
      </Body>
    </Back>
  );
}

function ContactUsView({ isAp }: IContactUsProps) {
  const [apSelect, setApSelect] = useState(isAp);
  function OpenSchedule(ap: boolean) {
    if (ap === true)
      handleOpenNewTab(
        "https://playsongshome.playsongs.ai/playsongs/timetable%403x.jpg"
      );
    else
      handleOpenNewTab(
        "https://playsongshome.playsongs.ai/playsongs/timetable_gwang%403x.jpg"
      );
  }

  return (
    <>
      <Mobile>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/contactus/mobile/banner.png"
          }
        />
      </Mobile>
      <Tablet>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/contactus/tablet/banner.png"
          }
        />
      </Tablet>
      <Desktop>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/contactus/banner.png"
          }
        />
      </Desktop>

      <SelectSectionView>
        <ButtonView onClick={() => setApSelect(true)} select={apSelect}>
          <span>압구정 센터</span>
        </ButtonView>
        <ButtonView onClick={() => setApSelect(false)} select={!apSelect}>
          <span>광교 센터</span>
        </ButtonView>
      </SelectSectionView>

      <SliderImage isAp={apSelect} />
      <AdressInfo isAp={apSelect} />
      <ScheduleInfo isAp={apSelect} />
    </>
  );
}

interface IContactusSlider {
  isAp: boolean;
}

function SliderImage({ isAp }: IContactusSlider) {
  return (
    <>
      <Carousel
        showArrows={true}
        centerMode={true}
        centerSlidePercentage={50}
        showThumbs={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop={false}
        selectedItem={1}
        className={"playsongs-contactus-imglist"}
      >
        {isAp
          ? ContactusData.map((content, index) => (
              <ContactUsSlider>
                <img
                  src={content.url}
                  alt="ApImage"
                  key={index}
                  style={{ marginLeft: "3.6458vw" }}
                />
              </ContactUsSlider>
            ))
          : ContactusDataG.map((content, index) => (
              <ContactUsSlider>
                <img
                  src={content.url}
                  alt="GWImage"
                  key={index}
                  style={{ marginLeft: "3.6458vw" }}
                />
              </ContactUsSlider>
            ))}
      </Carousel>
    </>
  );
}

function AdressInfo({ isAp }: IContactusSlider) {
  return (
    <>
      <ContactUsInfoZone>
        <ContactUsInfoSection>
          <ContactUsInfoTitleIcon>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/contactus/map.png"
              alt=""
              className="playsongs-contactus-titleicon-address"
            />
          </ContactUsInfoTitleIcon>
          <ContactUsInfoTitle>
            <span>주소</span>
          </ContactUsInfoTitle>
          <ContactUsInfoSubScript className="text_wrap">
            <span>
              {isAp ? (
                "서울 강남구 압구정로 113-22 2층 1호"
              ) : (
                <>
                  <Desktop>
                    경기 수원시 영통구 광교호수공원로 80 앨리웨이 키즈동
                    1층(109호)
                  </Desktop>
                  <TabletMobile>
                    경기 수원시 영통구 광교호수공원로 80{"\n"}앨리웨이 키즈동
                    1층 (109호)
                  </TabletMobile>
                </>
              )}
            </span>
          </ContactUsInfoSubScript>
        </ContactUsInfoSection>

        <ContactUsInfoSection>
          <ContactUsInfoTitleIcon>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/contactus/tel.png"
              alt=""
              className="playsongs-contactus-titleicon-tel"
            />
          </ContactUsInfoTitleIcon>
          <ContactUsInfoTitle>
            <span>전화번호</span>
          </ContactUsInfoTitle>
          <ContactUsInfoSubScript>
            <span>
              {isAp
                ? "1661-3210 (2번) / 02-544-6636"
                : "1661-3210 (3번) / 031-304-6636"}
            </span>
          </ContactUsInfoSubScript>
        </ContactUsInfoSection>

        <ContactUsInfoSection>
          <ContactUsInfoTitleIcon>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/contactus/time.png"
              alt=""
              className="playsongs-contactus-titleicon-time"
            />
          </ContactUsInfoTitleIcon>
          <ContactUsInfoTitle>
            <span>운영시간</span>
          </ContactUsInfoTitle>
          <ContactUsInfoSubScript>
            <span>
              {isAp ? "월~토 오전 10시~ 오후 6시" : "화~토 오전 10시~ 오후 6시"}
            </span>
          </ContactUsInfoSubScript>
        </ContactUsInfoSection>

        <ContactUsInfoSection>
          <ContactUsInfoTitleIcon>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/contactus/schedule.png"
              alt=""
              className="playsongs-contactus-titleicon-schedule"
            />
          </ContactUsInfoTitleIcon>
          <ContactUsInfoTitle>
            <span>휴무</span>
          </ContactUsInfoTitle>
          <ContactUsInfoSubScript>
            <span>{isAp ? "매주 일요일" : "매주 일요일/월요일"}</span>
          </ContactUsInfoSubScript>
        </ContactUsInfoSection>

        <ContactUsInfoSection>
          <ContactUsInfoTitleIcon>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/contactus/park.png"
              alt=""
              className="playsongs-contactus-titleicon-park"
            />
          </ContactUsInfoTitleIcon>
          <ContactUsInfoTitle>
            <span>주차</span>
          </ContactUsInfoTitle>
          <ContactUsInfoSubScript>
            <span>
              {isAp
                ? "압구정 미성상가 주차장 이용"
                : "광교 엘리웨이 주차장 이용"}
            </span>
          </ContactUsInfoSubScript>
        </ContactUsInfoSection>
      </ContactUsInfoZone>
    </>
  );
}

function ScheduleInfo({ isAp }: IContactusSlider) {
  function OpenSchedule(ap: boolean) {
    if (ap === true)
      handleOpenNewTab(
        "https://playsongshome.playsongs.ai/playsongs/timetable%403x.jpg"
      );
    else
      handleOpenNewTab(
        "https://playsongshome.playsongs.ai/playsongs/timetable_gwang%403x.jpg"
      );
  }

  return (
    <>
      {isAp ? (
        <>
          <Mobile>
            <SelectSectionView className="playsongs-contactus-selectSection">
              <ActionButton onClick={() => OpenSchedule(true)} select={true}>
                <span>시간표 확인하기</span>
              </ActionButton>
            </SelectSectionView>
          </Mobile>

          <Default>
            <SelectSectionView className="playsongs-contactus-selectSection">
              <ActionButton onClick={() => OpenSchedule(true)} select={true}>
                <span>시간표 확인하기</span>
              </ActionButton>
            </SelectSectionView>
          </Default>
        </>
      ) : (
        <>
          <SelectSectionView className="playsongs-contactus-selectSection">
            <ActionButton onClick={() => OpenSchedule(false)} select={true}>
              <span>시간표 확인하기</span>
            </ActionButton>
          </SelectSectionView>
        </>
      )}

      <ScheduleInfoView className="text_wrap">
        현재 보시는 시간표는 겨울학기 기준입니다.{"\n"}봄 학기 시간표는 변경될
        수 있습니다.{"\n"}
        참고용으로 확인부탁드립니다.{"\n"}
      </ScheduleInfoView>
    </>
  );
}
