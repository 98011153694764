import "../../Css/playsongs.css";
import BannerImage from "../../Componets/Banner/BannerImage";
import Footer from "../../Componets/Footer";
import { Desktop, Mobile, Tablet } from "../../Device";
import PHeader from "./PHeader";
import {
  Back,
  Body,
  ECImage,
  ECSubTitle,
  ECSubDescription,
  TitelView,
  PBody,
  ECSection2SubDescription,
  ECSection3,
  ECSection3Description,
  ECSection3Image,
  ECTitleView,
  ECTitle2View,
} from "./playsongsts";
import Strings from "./string";
import { useScreenType } from "../../Devicets";

export default function PEnglishClub() {
  return (
    <Back>
      <PHeader />
      <Body>
        <EnglishClubView />
        <Footer flagheight={true} />
      </Body>
    </Back>
  );
}

function EnglishClubView() {
  return (
    <>
      <Mobile>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/schedule/mo_banner%403x.png"
          }
        />
      </Mobile>
      <Tablet>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/schedule/ta_banner%403x.png"
          }
        />
      </Tablet>
      <Desktop>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/schedule/banner%403x.png"
          }
        />
      </Desktop>

      <PBody>
        <ECTitleView>TINY TOTS ENGLISH CLUB</ECTitleView>
        <InfoView />
      </PBody>
    </>
  );
}

function InfoView() {
  return (
    <>
      <ImageView />
      <Section1View />
      <Section2View />
      <Section3View />
    </>
  );
}

function ImageView() {
  return (
    <ECImage>
      <img
        src="https://playsongshome.playsongs.ai/playsongs/englishclub/pc/img.png"
        width={"100%"}
        alt="englishclub-img"
      />
    </ECImage>
  );
}

function Section1View() {
  return (
    <>
      <ECSubTitle>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
          width={"100%"}
          alt=""
        />
        <span>TINY TOTS ENGLISH CLUB?</span>
      </ECSubTitle>
      <ECSubDescription className="text_wrap">
        <span>
          TINY TOTS ENGLISH CLUB은 주로 2,3세의 어린 연령을 대상으로 하며,
          다양한 놀이의 형식으로 ​아이들의 첫 영어를 보다 친숙한 분위기에서
          자연스럽게 습득할 수 있도록 설계되었습니다.{"\n\n"}
          TINY TOTS ENGLISH CLUB은 플레이송스홈의 주제를 기반으로 수업이
          진행되며, ​아이들이 센터에서 접한 것들을 집에서도 연계하여 가정학습을
          할 수 있도록 하고 있습니다.{"\n"}또한, 이 수업은 영어유아교육에
          전문화된 방법과 단어들을 활용하여 아이들의 언어발달을 촉진하고, 동시에
          재미있는 활동을 통해 자기 표현 능력과 사회적 기술도 함께 강화합니다.
        </span>
      </ECSubDescription>
    </>
  );
}

function Section2View() {
  const screenType = useScreenType();

  return (
    <>
      <ECTitle2View className="ec-section-title">
        TINY TOTS ENGLISH CLUB의 목적
      </ECTitle2View>
      <ECSection2SubDescription className="text_wrap">
        <span>
          <span className="ec-section2-description-span">
            {Strings.englishClub.section2.step1.title}
          </span>
          {Strings.getEnglishClubSection2ForDevice(1, screenType)}
        </span>
        <br />
        <br />
        <span>
          <span className="ec-section2-description-span">
            {Strings.englishClub.section2.step2.title}
          </span>
          {Strings.getEnglishClubSection2ForDevice(2, screenType)}
        </span>
        <br />
        <br />

        <span>
          <span className="ec-section2-description-span">
            {Strings.englishClub.section2.step3.title}
          </span>
          {Strings.getEnglishClubSection2ForDevice(3, screenType)}
        </span>
        <br />
        <br />

        <span>
          <span className="ec-section2-description-span">
            {Strings.englishClub.section2.step4.title}
          </span>
          {Strings.getEnglishClubSection2ForDevice(4, screenType)}
        </span>
      </ECSection2SubDescription>
    </>
  );
}

function Section3View() {
  return (
    <>
      <Desktop>
        <ECSection3>
          <Section3DescriptionInfoView />
          <Section3InfoPhotoView />
        </ECSection3>
      </Desktop>
      <Tablet>
        <ECSection3>
          <Section3DescriptionInfoView />
          <Section3InfoPhotoView />
        </ECSection3>
      </Tablet>
      <Mobile>
        <Section3InfoPhotoView />
        <Section3DescriptionInfoView />
      </Mobile>
    </>
  );
}

function Section3DescriptionInfoView() {
  return (
    <ECSection3Description>
      <span>
        그러나, 가장 중요한 점은 영어를 접하는 과정이 재미있고, 자연스럽게
        이루어져야 한다는 것입니다.
        <br />
        <br />
        TINY TOTS ENGLISH CLUB은 놀이와 함께 언어를 자연스럽게 습득할 수 있는
        프로그램으로, 아이들이 재미있게 배우고 성장할 수 있는 환경을 제공합니다.
        <br />
        <br />
        TINY TOTS ENGLISH CLUB에서 아이들이 즐겁고 유익한 시간을 보낼 수 있도록
        노력하겠습니다.
      </span>
    </ECSection3Description>
  );
}

function Section3InfoPhotoView() {
  return (
    <ECSection3Image>
      <Desktop>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/englishclub/pc/en-teacher.png"
          width={"100%"}
          alt="englishclub-image3"
        />
      </Desktop>
      <Tablet>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/englishclub/tablet/en-teacher.png"
          width={"100%"}
          alt="englishclub-image3"
        />
      </Tablet>
      <Mobile>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/englishclub/pc/en-teacher.png"
          width={"100%"}
          alt="englishclub-image3"
        />
      </Mobile>
    </ECSection3Image>
  );
}
