import "../../Css/playsongs.css";
import { styled } from "styled-components";
import Footer from "../../Componets/Footer";
import PHeader from "./PHeader";
import { Desktop, Mobile, Tablet, TabletMobile } from "../../Device";
import BannerImage from "../../Componets/Banner/BannerImage";
import {
  Back,
  Body,
  CharView,
  SubDescription,
  SubTitle,
  SubZone,
  TitelView,
} from "./playsongsts";

export default function AboutUs() {
  return (
    <Back>
      <PHeader />
      <Body>
        <ViewAboutUs />
        <Footer flagheight={true} />
      </Body>
    </Back>
  );
}

const TitelView2 = styled(TitelView)`
  margin-top: 6.25vw;

  @media (max-width: 767px) {
    margin-top: 14vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 13.0208vw;
  }
`;
const DescriptlView = styled.div`
  margin-top: 2.0833vw;
  display: flex;
  justify-content: center;
  width: 85.5814vw;
  margin-left: auto;
  margin-right: auto;

  span {
    font-size: 1.25vw;
    line-height: 1.5;
    letter-spacing: -0.0375vw;
    text-align: left;
    color: #000;

    @media (max-width: 767px) {
      font-size: 4.186vw;
      line-height: 1.44;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.3438vw;
      line-height: 1.44;
    }
  }
`;

const CircleZone1 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3.75vw;
  margin-left: auto;
  margin-right: auto;
  width: 64.4792vw;
`;

const CircleZone2 = styled(CircleZone1)`
  margin-top: 1.0417vw;
  width: 53.4896vw;
`;

const Section = styled.div`
  position: relative;
`;

const CircleView = styled.div`
  width: 9.5313vw;
  height: 9.5313vw;
  border-radius: 50%;
  background-color: #ececec;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: #08348a;

    @media (max-width: 767px) {
      font-size: 4.6512vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.6042vw;
    }
  }

  @media (max-width: 767px) {
    width: 23.2558vw;
    height: 23.2558vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 13.0208vw;
    height: 13.0208vw;
  }
`;



function ViewAboutUs() {
  return (
    <>
      <Mobile>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/aboutus/mobile/aboutus-banner.png"
          }
        />
      </Mobile>
      <Tablet>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/aboutus/tablet/aboutus-banner.jpg"
          }
        />
      </Tablet>
      <Desktop>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/aboutus/aboutus-banner.jpg"
          }
        />
      </Desktop>

      <TitelView>전문가 그룹이 만들고 검증한{"\n"} 통합교육 프로그램</TitelView>
      <DescriptlView className="text_wrap">
        <span>
          <Mobile>
            16년간 10만명 아이들의 체험을 통해 완성한 1,300개의 자체 개발
            놀이법과 놀이에{"\n"}
            적합한 800개의 교구들을 보유하고 있습니다.{"\n"}
            플레이송스에서는 발달 시기별 도전과제가 있는{"\n"}
            단계별 적기 놀이와 긍정적인 상호작용놀이 경험으로{"\n"}
            11가지 영역을 균형 있게 발달시킵니다.
          </Mobile>
          <Tablet>
            16년간 10만명 아이들의 체험을 통해 완성한 1,300개의 자체 개발
            놀이법과 놀이에{"\n"}
            적합한 800개의 교구들을 보유하고 있습니다.{"\n"}
            플레이송스에서는 발달 시기별 도전과제가 있는 단계별 적기 놀이와
            {"\n"}
            긍정적인 상호작용놀이 경험으로 11가지 영역을 균형 있게 발달시킵니다.
          </Tablet>
          <Desktop>
            16년간 10만명 아이들의 체험을 통해 완성한 1,300개의 자체 개발
            놀이법과 놀이에 적합한 800개의 교구들을 보유하고 있습니다.{"\n"}
            플레이송스에서는 발달 시기별 도전과제가 있는 단계별 적기 놀이와
            긍정적인 상호작용놀이 경험으로 11가지 영역을 균형 있게 발달시킵니다.
          </Desktop>
        </span>
      </DescriptlView>

      <Desktop>
        <DesktopCircle />
      </Desktop>
      <Tablet>
        <TabletCircle />
      </Tablet>
      <Mobile>
        <MobileCircle />
      </Mobile>

      <TitelView2 className="text_wrap">
        <Desktop>플레이송스만의 오리지널 명품 음악</Desktop>
        <TabletMobile>
          플레이송스만의{"\n"}
          오리지널 명품 음악
        </TabletMobile>
      </TitelView2>
      <Desktop>
        <DesktopSubDescription />
      </Desktop>
      <TabletMobile>
        <TabletMobileSubDescription />
      </TabletMobile>

      <TitelView2>놀이로 경험해요</TitelView2>
      <Desktop>
        <DesktopRectangle />
      </Desktop>
      <TabletMobile>
        <TabletMobileRectangle />
      </TabletMobile>
    </>
  );
}

function DesktopCircle() {
  return (
    <>
      <CircleZone1>
        <Section>
          <CircleView>
            {" "}
            <span>음악성</span>
          </CircleView>
          <CharView
            className="playsongs-brouce"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/bruce.png"
            alt=""
            width={"100%"}
          />
        </Section>
        <CircleView>
          {" "}
          <span>집중력</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>정서</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>창의성</span>
        </CircleView>
        <Section>
          <CircleView>
            {" "}
            <span>인지능력</span>
          </CircleView>
          <CharView
            className="playsongs-charlie"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/charlie.png"
            alt=""
            width={"100%"}
          />
        </Section>
        <CircleView>
          {" "}
          <span>사고력</span>
        </CircleView>
      </CircleZone1>
      <CircleZone2>
        <Section>
          <CircleView>
            {" "}
            <span>안정애착</span>
          </CircleView>
          <CharView
            className="playsongs-tony"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/tony.png"
            alt=""
            width={"100%"}
          />
        </Section>
        <CircleView>
          {" "}
          <span>운동능력</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>자발성</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>사회성</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>언어능력</span>
        </CircleView>
      </CircleZone2>
    </>
  );
}

const CircleTabletZone1 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 7.4219vw;
  margin-left: auto;
  margin-right: auto;
  width: 59.9089vw;
`;

const CircleTabletZone2 = styled(CircleTabletZone1)`
  margin-top: 5.2083vw;
  width: 44.2708vw;
`;

function TabletCircle() {
  return (
    <>
      <CircleTabletZone1>
        <Section>
          <CircleView>
            {" "}
            <span>음악성</span>
          </CircleView>
          <CharView
            className="playsongs-brouce"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/bruce.png"
            alt=""
            width={"100%"}
          />
        </Section>
        <CircleView>
          {" "}
          <span>집중력</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>정서</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>창의성</span>
        </CircleView>
      </CircleTabletZone1>
      <CircleTabletZone2>
        <Section>
          <CircleView>
            {" "}
            <span>인지능력</span>
          </CircleView>
          <CharView
            className="playsongs-charlie"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/tablet/charlie.png"
            alt=""
            width={"100%"}
          />
        </Section>
        <CircleView>
          {" "}
          <span>사고력</span>
        </CircleView>
        <Section>
          <CircleView>
            {" "}
            <span>안정애착</span>
          </CircleView>
          <CharView
            className="playsongs-tony"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/tablet/tony.png"
            alt=""
            width={"100%"}
          />
        </Section>
      </CircleTabletZone2>
      <CircleTabletZone1 style={{ marginTop: "5.2083vw" }}>
        <CircleView>
          {" "}
          <span>운동능력</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>자발성</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>사회성</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>언어능력</span>
        </CircleView>
      </CircleTabletZone1>
    </>
  );
}

const CircleMobileZone1 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4.6512vw;
  margin-left: auto;
  margin-right: auto;
  width: 79.0698vw;
`;

const CircleMobileZone2 = styled(CircleMobileZone1)`
  margin-top: 5.7292vw;
  width: 51.1628vw;
`;

function MobileCircle() {
  return (
    <>
      <CircleMobileZone1>
        <Section>
          <CircleView>
            {" "}
            <span>음악성</span>
          </CircleView>
          <CharView
            className="playsongs-brouce"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/bruce.png"
            alt=""
            width={"100%"}
          />
        </Section>
        <CircleView>
          {" "}
          <span>집중력</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>정서</span>
        </CircleView>
      </CircleMobileZone1>
      <CircleMobileZone1>
        <CircleView>
          {" "}
          <span>창의성</span>
        </CircleView>
        <Section>
          <CircleView>
            {" "}
            <span>인지능력</span>
          </CircleView>
          <CharView
            className="playsongs-charlie"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/tablet/charlie.png"
            alt=""
            width={"100%"}
          />
        </Section>
        <CircleView>
          {" "}
          <span>사고력</span>
        </CircleView>
      </CircleMobileZone1>
      <CircleMobileZone1>
        <Section>
          <CircleView>
            {" "}
            <span>안정애착</span>
          </CircleView>
          <CharView
            className="playsongs-tony"
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/tablet/tony.png"
            alt=""
            width={"100%"}
          />
        </Section>
        <CircleView>
          {" "}
          <span>운동능력</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>자발성</span>
        </CircleView>
      </CircleMobileZone1>
      <CircleMobileZone2>
        <CircleView>
          {" "}
          <span>사회성</span>
        </CircleView>
        <CircleView>
          {" "}
          <span>언어능력</span>
        </CircleView>
      </CircleMobileZone2>
    </>
  );
}

function DesktopSubDescription() {
  return (
    <>
      <SubZone style={{ marginTop: "2.0833vw" }}>
        <SubTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>16년 동안 사랑받은 음악</span>
        </SubTitle>
        <SubDescription>
          <span>
            미국 브로드웨이 출신 음악가들, 국내외 유명 음악치료사, 유아교육
            전문가들이 합작하여 개발한 영유아 전문 음악들로 16년간 수많은
            아이들, 학부모들에게 사랑받은 800개의 순수 창작곡을 보유하고
            있습니다.
          </span>
        </SubDescription>
      </SubZone>
      <SubZone style={{ marginTop: "4.1667vw" }}>
        <SubTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>문화를 체험하는 음악</span>
        </SubTitle>
        <SubDescription>
          <span>
            아이의 음악지능을 발달시키고 평생 풍부한 음악을 즐기고 표현할 수
            있도록 재즈 컨트리, 라틴, 로큰롤, 중동, 동양음악 등 다양한 장르의
            음악을 아이들의 눈높이에 맞추어 제작하였습니다.
          </span>
        </SubDescription>
      </SubZone>
      <SubZone style={{ marginTop: "4.1667vw" }}>
        <SubTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>발달 과정에 맞춘 기획된 음악</span>
        </SubTitle>
        <SubDescription>
          <span>
            플레이송스의 음악은 영유아기의 세부적인 발달 과정을 고려하여 아이가
            능동적으로 사고하고, 놀이하고, 춤추고, 상상하고, 운동할 수 있게
            기획된 음악입니다.
          </span>
        </SubDescription>
      </SubZone>
    </>
  );
}

function TabletMobileSubDescription() {
  return (
    <div className="playsongs-subtitle">
      <SubTitle>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
          width={"100%"}
          alt=""
        />
        <span>16년 동안 사랑받은 음악</span>
      </SubTitle>
      <SubDescription>
        <span>
          미국 브로드웨이 출신 음악가들, 국내외 유명 음악치료사, 유아교육
          전문가들이 합작하여 개발한 영유아 전문 음악들로 16년간 수많은 아이들,
          학부모들에게 사랑받은 800개의 순수 창작곡을 보유하고 있습니다.
        </span>
      </SubDescription>
      <SubTitle>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
          width={"100%"}
          alt=""
        />
        <span>문화를 체험하는 음악</span>
      </SubTitle>
      <SubDescription>
        <span>
          아이의 음악지능을 발달시키고 평생 풍부한 음악을 즐기고 표현할 수
          있도록 재즈 컨트리, 라틴, 로큰롤, 중동, 동양음악 등 다양한 장르의
          음악을 아이들의 눈높이에 맞추어 제작하였습니다.
        </span>
      </SubDescription>
      <SubTitle>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
          width={"100%"}
          alt=""
        />
        <span>발달 과정에 맞춘 기획된 음악</span>
      </SubTitle>
      <SubDescription>
        <span>
          플레이송스의 음악은 영유아기의 세부적인 발달 과정을 고려하여 아이가
          능동적으로 사고하고, 놀이하고, 춤추고, 상상하고, 운동할 수 있게 기획된
          음악입니다.
        </span>
      </SubDescription>
    </div>
  );
}

const RectangleZone = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10.5208vw;
  width: 66.4063vw;
`;
const RectangleView = styled.div`
  width: 21.3542vw;
  height: 19.5313vw;
  border-radius: 0.4688vw;
  background-color: #ffe8ee;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: #08348a;

    @media (max-width: 767px) {
      font-size: 4.6512vw;
      line-height: 1.4;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 3.125vw;
      line-height: 1.21;
    }
  }

  p {
    margin-top: 1.0417vw;
    font-size: 1.0417vw;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
    color: #000;

    @media (max-width: 767px) {
      margin-top: 2.3256vw;
      font-size: 4.186vw;
      line-height: 1.39;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 1.3021vw;
      font-size: 2.3438vw;
    }
  }

  @media (max-width: 767px) {
    width: 85.5814vw;
    height: 46.5116vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 4.6512vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    height: 26.0417vw;

    display: flex;
    justify-content: center;
    margin-top: 2.6042vw;
    margin-left: auto;
    margin-right: auto;
  }
`;
function DesktopRectangle() {
  return (
    <RectangleZone>
      <Section>
        <RectangleView>
          <span className="text_wrap">
            놀이는{"\n"}
            유아의 지능을{"\n"}
            발달시킨다.
          </span>
          <p className="text_wrap">
            인지발달이론을 만든{"\n"}
            스위스의 심리학자 장 피아제
          </p>
        </RectangleView>
        <CharView
          className="playsongs-max"
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/max.png"
          width={"100%"}
          alt=""
        />
      </Section>

      <Section>
        <RectangleView>
          <span className="text_wrap">
            놀이는 규칙을 파악하고{"\n"}
            학습하게 하며,{"\n"}
            사고력을 높이고,{"\n"}
            정신 기능의 발달과 함께{"\n"}
            자기 조절력을 증진시킨다.
          </span>
          <p className="text_wrap">
            케임브리지 대학 심리학자{"\n"}
            이언 크로스
          </p>
        </RectangleView>
        <CharView
          className="playsongs-joy"
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/joy.png"
          width={"100%"}
          alt=""
        />
      </Section>

      <Section>
        <RectangleView>
          <span className="text_wrap">
            놀이는 문제 해결 능력을 높이고,{"\n"}
            지적 융통성과 창의성을{"\n"}
            증진시키며,{"\n"}
            운동 능력과 언어 능력을
            {"\n"}증진시킨다.
          </span>
          <p className="text_wrap">
            미국의 교육심리학자{"\n"}
            제롬 브루너
          </p>
        </RectangleView>
        <CharView
          className="playsongs-mystery"
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/mystery.png"
          width={"100%"}
          alt=""
        />
      </Section>
    </RectangleZone>
  );
}

function TabletMobileRectangle() {
  return (
    <div style={{ marginTop: "1.3281vw" }}>
      <Section className="playsongs-rectangle1">
        <RectangleView>
          <span className="text_wrap">
            {"\n"}
            놀이는 유아의 지능을 발달시킨다.
          </span>
          <p className="text_wrap">
            인지발달이론을 만든{"\n"}
            스위스의 심리학자 장 피아제
          </p>
        </RectangleView>
        <CharView
          className="playsongs-max"
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/max.png"
          width={"100%"}
          alt=""
        />
      </Section>

      <Section className="playsongs-rectangle2">
        <RectangleView>
          <span className="text_wrap">
            놀이는 규칙을 파악하고 학습하게 하며,{"\n"}
            사고력을 높이고, 정신 기능의 발달과 함께{"\n"}
            자기 조절력을 증진시킨다.
          </span>
          <p className="text_wrap">
            케임브리지 대학 심리학자{"\n"}
            이언 크로스
          </p>
        </RectangleView>
        <CharView
          className="playsongs-joy"
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/joy.png"
          width={"100%"}
          alt=""
        />
      </Section>

      <Section className="playsongs-rectangle3">
        <RectangleView>
          <span className="text_wrap">
            {"\n"}
            놀이는 문제해결능력을 높이고,{"\n"}
            지적 융통성과 창의성을 증진시키며,{"\n"}
            운동능력과 언어능력을 증진시킨다.
          </span>
          <p className="text_wrap">
            미국의 교육심리학자{"\n"}
            제롬 브루너
          </p>
        </RectangleView>
        <CharView
          className="playsongs-mystery"
          src="https://playsongshome.playsongs.ai/playsongs/aboutus/mystery.png"
          width={"100%"}
          alt=""
        />
      </Section>
    </div>
  );
}
