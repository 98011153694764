import "./../Css/playsongs.css";
import { Helmet } from "react-helmet-async";
import Footer from "../Componets/Footer";
import PHeader from "../screens/Playsongs/PHeader";
import {
  Back,
  Body,
  CharView,
  HomeAboutusBtnView,
  HomeAboutusCharView,
  HomeAboutusSubTextView,
  HomeAboutusTextSection,
  HomeAboutusTitleTextView,
  HomeBannerView,
  HomeCenterSection,
  HomeCenterZone,
  HomeContactusAp,
  HomeContactusSection,
  HomeContactusTitleView,
  HomeMall,
  HomeMallTitle2View,
  HomeMallTitleView,
  HomeMallZone,
  HomeSchedule,
  HomeScheduleTitle2View,
  HomeScheduleTitleView,
  HomeScheduleZone,
  HomeSection,
  HomeZone,
  HomeZoneBanner,
  HomeZoneContactus,
  TabletHomeCenterSectionLeft,
  TabletHomeCenterSectionRight,
} from "../screens/Playsongs/playsongsts";
import { Banner } from "../Componets/Banner/Banner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleOpenNewTab } from "../Componets/common";
import { Default, Desktop, Mobile, Tablet, TabletMobile } from "../Device";
import Popup from "../Componets/Popup";

export default function Playsongs() {
  return (
    <Back>
      <Helmet>
        <title>Playsongs</title>
        <link rel="icon" href={"pfavicon.ico"} />
      </Helmet>

      <PHeader />
      <Body>
        <PlaysongsView />
        <Footer flagheight={false} />
      </Body>
    </Back>
  );
}

function PlaysongsView() {
  return (
    <>
      <PopupView />
      <BannerView />
      <AboutusView />
      <ContactusView />
      <ScheduleView />
      <MallView />
    </>
  );
}

function PopupView() {
  //// popup
  //const [popupData, setPopupData] = useState<PopupData | null>();
  const [openPopup, setOpenPopup] = useState(false);
  const [load, setLoad] = useState(true);

  return (
    <>
      {openPopup && load && (
        <Popup
          closePopup={() => setOpenPopup(false)}
          clicklink={
            "https://docs.google.com/forms/d/e/1FAIpQLSd6ULeZOjlqx0ShAXUwUmaNK4_ttcHylBAX2aFunNr9E2msZA/viewform?fbzx=-6714419400318400078"
          }
          imglink={
            "https://playsongshome.playsongs.ai/popup/2024%E1%84%8B%E1%85%A7%E1%84%85%E1%85%B3%E1%86%B7_%E1%84%89%E1%85%AE%E1%84%80%E1%85%A1%E1%86%BC%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A2%E1%84%91%E1%85%A1%E1%86%B8%E1%84%8B%E1%85%A5%E1%86%B8600-600_bt1.jpg"
          }
        />
      )}
    </>
  );
}

function BannerView() {
  const [show, setShow] = useState(true);

  return (
    <HomeZoneBanner $bgColor="#007ac3">
      <HomeBannerView>
        <Banner
          setShow={setShow}
          url="https://playsongshome.playsongs.ai/playsongs/banner.mp4"
        />
      </HomeBannerView>
    </HomeZoneBanner>
  );
}

function AboutusView() {
  return (
    <>
      <Desktop>
        <DesktopAboutusView />
      </Desktop>
      <TabletMobile>
        <TableMobileAboutusView />
      </TabletMobile>
    </>
  );
}

function TableMobileAboutusView() {
  const goAboutus = () => window.location.replace("/aboutus");

  return (
    <HomeZone $bgColor="#ececec" className="aboutus-zone">
      <HomeSection>
        <HomeAboutusTextSection>
          <HomeAboutusTitleTextView>
            왜 음악으로 교육하는가?
          </HomeAboutusTitleTextView>
          <HomeAboutusSubTextView className="text_wrap">
            기획된 음악을 잘 활용하면{"\n"}교육적 효과가 극대화됩니다.
          </HomeAboutusSubTextView>
          <HomeAboutusBtnView onClick={goAboutus}>
            <span>자세히 보기</span>
          </HomeAboutusBtnView>
        </HomeAboutusTextSection>
        <HomeAboutusCharView>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/home/mobile/aboutus.png"
            alt=""
            width={"100%"}
          />
        </HomeAboutusCharView>
      </HomeSection>
    </HomeZone>
  );
}

function DesktopAboutusView() {
  const goAboutus = () => window.location.replace("/aboutus");

  return (
    <HomeZone $bgColor="#ececec" className="aboutus-zone">
      <HomeSection>
        <HomeAboutusTextSection>
          <HomeAboutusTitleTextView className="text_wrap">
            왜 음악으로{"\n"}교육하는가?{"?"}
          </HomeAboutusTitleTextView>
          <HomeAboutusSubTextView className="text_wrap">
            기획된 음악을 잘 활용하면{"\n"}교육적 효과가 극대화됩니다.
          </HomeAboutusSubTextView>
          <HomeAboutusBtnView onClick={goAboutus}>
            <span>자세히 보기</span>
          </HomeAboutusBtnView>
        </HomeAboutusTextSection>
        <HomeAboutusCharView>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/home/aboutus.png"
            alt=""
            width={"100%"}
          />
        </HomeAboutusCharView>
      </HomeSection>
    </HomeZone>
  );
}

function ContactusView() {
  return (
    <>
      <Desktop>
        <DesktopContactusView />
      </Desktop>
      <TabletMobile>
        <TabletContactusView />
      </TabletMobile>
    </>
  );
}

function TabletContactusView() {
  const navigate = useNavigate();

  const goCenterAp = () => {
    navigate("/contactus", {
      state: {
        isAp: true,
      },
    });
  };
  //window.location.replace("/contatus");
  const goCenterGw = () => {
    navigate("/contactus", {
      state: {
        isAp: false,
      },
    });
  };

  return (
    <HomeZoneContactus $bgColor="#fee49a">
      <HomeContactusSection>
        <HomeContactusTitleView className="text_wrap">
          <span>
            <Tablet>플레이송스 센터를 소개합니다!</Tablet>
            <Mobile>플레이송스 센터를{"\n"}소개합니다!</Mobile>
          </span>
        </HomeContactusTitleView>
        <HomeCenterZone>
          <TabletHomeCenterSectionLeft>
            <HomeContactusAp onClick={goCenterAp}>
              <img
                src="https://playsongshome.playsongs.ai/playsongs/home/pc-main-a%403x.png"
                alt=""
                width={"100%"}
              />
              <span>압구정 센터</span>
            </HomeContactusAp>
            <CharView
              className="playsongs-home-alex"
              src="https://playsongshome.playsongs.ai/playsongs/home/alex.png"
              alt=""
              width={"100%"}
            />
          </TabletHomeCenterSectionLeft>

          <TabletHomeCenterSectionRight>
            <HomeContactusAp onClick={goCenterGw}>
              <img
                src="https://playsongshome.playsongs.ai/playsongs/home/pc-main-gw%403x.png"
                alt=""
                width={"100%"}
              />
              <span>광교 센터</span>
            </HomeContactusAp>
            <CharView
              className="playsongs-home-max"
              src="https://playsongshome.playsongs.ai/playsongs/home/max.png"
              alt=""
              width={"100%"}
            />
          </TabletHomeCenterSectionRight>
        </HomeCenterZone>
      </HomeContactusSection>
    </HomeZoneContactus>
  );
}

function DesktopContactusView() {
  const navigate = useNavigate();

  const goCenterAp = () => {
    navigate("/contactus", {
      state: {
        isAp: true,
      },
    });
  };
  //window.location.replace("/contatus");
  const goCenterGw = () => {
    navigate("/contactus", {
      state: {
        isAp: false,
      },
    });
  };

  return (
    <HomeZone $bgColor="#fee49a">
      <HomeContactusSection>
        <HomeContactusTitleView>
          <span>플레이송스 센터를 소개합니다!</span>
        </HomeContactusTitleView>
        <HomeCenterZone>
          <HomeCenterSection className="home-center-section-left">
            <HomeContactusAp onClick={goCenterAp}>
              <img
                src="https://playsongshome.playsongs.ai/playsongs/home/pc-main-a%403x.png"
                alt=""
                width={"100%"}
              />
              <span>압구정 센터</span>
            </HomeContactusAp>
            <CharView
              className="playsongs-home-alex"
              src="https://playsongshome.playsongs.ai/playsongs/home/alex.png"
              alt=""
              width={"100%"}
            />
          </HomeCenterSection>

          <HomeCenterSection className="home-center-section-right">
            <HomeContactusAp onClick={goCenterGw}>
              <img
                src="https://playsongshome.playsongs.ai/playsongs/home/pc-main-gw%403x.png"
                alt=""
                width={"100%"}
              />
              <span>광교 센터</span>
            </HomeContactusAp>
            <CharView
              className="playsongs-home-max"
              src="https://playsongshome.playsongs.ai/playsongs/home/max.png"
              alt=""
              width={"100%"}
            />
          </HomeCenterSection>
        </HomeCenterZone>
      </HomeContactusSection>
    </HomeZone>
  );
}

function ScheduleView() {
  const goSchedule = () => window.location.replace("/schedule");

  return (
    <HomeZone $bgColor="#fcd0db" className="schedule-zone">
      <HomeScheduleZone>
        <HomeScheduleTitleView>
          <span>플레이송스</span>
        </HomeScheduleTitleView>
        <HomeScheduleTitle2View>
          <span>수강신청하기</span>
        </HomeScheduleTitle2View>
        <HomeSchedule onClick={goSchedule}>
          <Default>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/home/schedule.png"
              alt=""
              width={"100%"}
            />
          </Default>
          <Mobile>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/home/mobile/schedule.png"
              alt=""
              width={"100%"}
            />
          </Mobile>
        </HomeSchedule>
      </HomeScheduleZone>
    </HomeZone>
  );
}

function MallView() {
  return (
    <HomeZone $bgColor="#00a553" className="mall-zone">
      <HomeMallZone>
        <HomeMallTitleView>
          <span>플송스토어를 통해</span>
        </HomeMallTitleView>
        <HomeMallTitle2View className="text_wrap">
          <span>
            <Default>우리집도 플레이송스 센터가 될 수 있어요!</Default>
            <Mobile>우리집도 플레이송스 센터가{"\n"}될 수 있어요!</Mobile>
          </span>
        </HomeMallTitle2View>
        <HomeMall
          onClick={() =>
            handleOpenNewTab("https://smartstore.naver.com/bicycle")
          }
        >
          <img
            src="https://playsongshome.playsongs.ai/playsongs/home/naverstore.png"
            alt=""
            width={"100%"}
          />{" "}
        </HomeMall>
      </HomeMallZone>
    </HomeZone>
  );
}
/*

<Notice />
import Insta from "../Componets/Instagram/Insta";
import Youtube from "../Componets/Youtube/Youtube";


<Youtube />

*/
