export const ContactusData = [
    {
        label: "Image 1",
        alt: "image1",
        url: "https://playsongshome.playsongs.ai/playsongs/contactus/center-pc-a-1%403x.png",
    },

    {
        label: "Image 2",
        alt: "image2",
        url: "https://playsongshome.playsongs.ai/playsongs/contactus/center-pc-a-2%403x.png",
    },

    {
        label: "Image 3",
        alt: "image3",
        url: "https://playsongshome.playsongs.ai/playsongs/contactus/center-pc-a-3%403x.png",
    },

    {
        label: "Image 4",
        alt: "image4",
        url: "https://playsongshome.playsongs.ai/playsongs/contactus/center-pc-a-4%403x.png",
    },
]

export const ContactusDataG = [
    {
        label: "Image 1",
        alt: "image1",
        url: "https://playsongshome.playsongs.ai/playsongs/contactus/center-pc-g-1%403x.png",
    },

    {
        label: "Image 2",
        alt: "image2",
        url: "https://playsongshome.playsongs.ai/playsongs/contactus/center-pc-g-2%403x.png",
    },

    {
        label: "Image 3",
        alt: "image3",
        url: "https://playsongshome.playsongs.ai/playsongs/contactus/center-pc-g-3%403x.png",
    },

    {
        label: "Image 4",
        alt: "image4",
        url: "https://playsongshome.playsongs.ai/playsongs/contactus/center-pc-g-4%403x.png",
    },
]