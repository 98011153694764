import "../../Css/playsongs.css";
import BannerImage from "../../Componets/Banner/BannerImage";
import Footer from "../../Componets/Footer";
import { Desktop, Mobile, Tablet } from "../../Device";
import PHeader from "./PHeader";
import {
  Back,
  Body,
  CurriculumInfoDescription,
  CurriculumInfoImg,
  CurriculumInfoSection,
  CurriculumInfoTitle,
  CurriculumInfoZone,
  SetDescriptSetion,
  StepDescriptView,
  StepTitleSection,
  StepTitleView,
  TitelView,
} from "./playsongsts";

export default function Curriculum() {
  return (
    <Back>
      <PHeader />
      <Body>
        <CurriculumView />
        <Footer flagheight={true} />
      </Body>
    </Back>
  );
}

function CurriculumView() {
  return (
    <>
      <Mobile>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/curriculum/mobile/banner.png"
          }
        />
      </Mobile>
      <Tablet>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/curriculum/tablet/banner.png"
          }
        />
      </Tablet>
      <Desktop>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/curriculum/banner.png"
          }
        />
      </Desktop>

      <StepInfoView />
      <CurriculumInfoView />
    </>
  );
}

function CurriculumInfoView() {
  return (
    <>
      <Desktop>
        <DesktopCurriculumInfoView />
      </Desktop>
      <Tablet>
        <TabletCurriculumInfoView />
      </Tablet>
      <Mobile>
        <MobileCurriculumInfoView />
      </Mobile>
    </>
  );
}

function MobileCurriculumInfoView() {
  return (
    <>
      <CurriculumInfoZone>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>몰입하고 성취하는 아이들​</span>
        </CurriculumInfoTitle>
        <CurriculumInfoDescription>
          <span>
            아름다운 음악과 신기한 이야기를 중심으로 경험하는 긴밀하고 격렬한
            상호작용은 감성을 깊숙이 자극하고 아이들을 몰입하게 합니다. 발달
            단계별 발달과업을 정밀하게 다룬 프로그램은 아이가 모든 활동에 스스로
            참여하고 성취할 수 있게 합니다.
          </span>
        </CurriculumInfoDescription>
        <CurriculumInfoImg>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/curriculum/mobile/mo-curriculum-1%403x.png"
            alt=""
            width={"100%"}
          />
        </CurriculumInfoImg>
      </CurriculumInfoZone>

      <CurriculumInfoZone>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>소수정예 그룹, 2명의 선생님</span>
        </CurriculumInfoTitle>
        <CurriculumInfoDescription>
          <span>
            1회 40분 수업으로 2명의 교사와 5명의 아이들이 함께합니다. 선생님이
            직접 연주하고 노래하며 아이 한 명 한 명과 교감할 수 있도록
            구성되었습니다.
          </span>
        </CurriculumInfoDescription>
        <CurriculumInfoImg>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/curriculum/pc-curriculum-2%403x.png"
            alt=""
            width={"100%"}
          />
        </CurriculumInfoImg>
      </CurriculumInfoZone>

      <CurriculumInfoZone>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>국내 유일의 전문 교사</span>
        </CurriculumInfoTitle>
        <CurriculumInfoDescription>
          <span>
            플레이송스의 모든 교사들은 MASTER MUSIC AND PLAY PRACTITIONER을
            이수하고 테스트에 합격하여 MMP자격증을 보유하고 있습니다. 또한
            지속적인 Self-critic과 엄격한 Supervising System을 통해 전문성과
            역량을 최고 수준으로 유지합니다.
          </span>
        </CurriculumInfoDescription>
        <CurriculumInfoImg>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/curriculum/mmp-1%403x.png"
            alt=""
            width={"100%"}
          />
        </CurriculumInfoImg>
      </CurriculumInfoZone>

      <CurriculumInfoZone>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>차별화된 서비스</span>
        </CurriculumInfoTitle>
        <CurriculumInfoDescription
          className="text_wrap"
          style={{ marginTop: "2.6042vw", width: "79.1667vw" }}
        >
          <span>
            - 친근감과 생동감을 불어넣는 자체 캐릭터들과{"\n"}   다채로운 시청각 자료, 최고급 수준의 교구를{"\n"}   제공합니다.{"\n"}
            - 플레이송스 AI시스템으로 맞춤형 프로그램과{"\n"}   교구재를 추천하고 있습니다.{"\n"}
            - 수업촬영 사진을 키즈노트에 업로드해 드립니다.
          </span>
        </CurriculumInfoDescription>
      </CurriculumInfoZone>
    </>
  );
}

function TabletCurriculumInfoView() {
  return (
    <>
      <CurriculumInfoZone>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>몰입하고 성취하는 아이들​</span>
        </CurriculumInfoTitle>
        <CurriculumInfoSection>
          <CurriculumInfoDescription>
            <span>
              아름다운 음악과 신기한 이야기를 중심으로 경험하는 긴밀하고 격렬한
              상호작용은 감성을 깊숙이 자극하고 아이들을 몰입하게 합니다. 발달
              단계별 발달과업을 정밀하게 다룬 프로그램은 아이가 모든 활동에
              스스로 참여하고 성취할 수 있게 합니다.
            </span>
          </CurriculumInfoDescription>
          <CurriculumInfoImg>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/curriculum/tablet/ta-curriculum-1%403x.png"
              alt=""
              width={"100%"}
            />
          </CurriculumInfoImg>
        </CurriculumInfoSection>
      </CurriculumInfoZone>

      <CurriculumInfoZone>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>소수정예 그룹, 2명의 선생님</span>
        </CurriculumInfoTitle>
        <CurriculumInfoSection>
          <CurriculumInfoDescription>
            <span>
              1회 40분 수업으로 2명의 교사와 5명의 아이들이 함께합니다. 선생님이
              직접 연주하고 노래하며 아이 한 명 한 명과 교감할 수 있도록
              구성되었습니다.
            </span>
          </CurriculumInfoDescription>
          <CurriculumInfoImg>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/curriculum/tablet/ta-curriculum-2%403x.png"
              alt=""
              width={"100%"}
            />
          </CurriculumInfoImg>
        </CurriculumInfoSection>
      </CurriculumInfoZone>

      <CurriculumInfoZone>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>국내 유일의 전문 교사</span>
        </CurriculumInfoTitle>
        <CurriculumInfoSection>
          <CurriculumInfoDescription>
            <span>
              플레이송스의 모든 교사들은 MASTER MUSIC AND PLAY PRACTITIONER을
              이수하고 테스트에 합격하여 MMP자격증을 보유하고 있습니다. 또한
              지속적인 Self-critic과 엄격한 Supervising System을 통해 전문성과
              역량을 최고 수준으로 유지합니다.
            </span>
          </CurriculumInfoDescription>
          <CurriculumInfoImg>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/curriculum/tablet/mmp-1%403x.png"
              alt=""
              width={"100%"}
            />
          </CurriculumInfoImg>
        </CurriculumInfoSection>
      </CurriculumInfoZone>

      <CurriculumInfoZone>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>차별화된 서비스</span>
        </CurriculumInfoTitle>
        <CurriculumInfoDescription
          className="text_wrap"
          style={{ marginTop: "2.6042vw", width: "79.1667vw" }}
        >
          <span>
            - 친근감과 생동감을 불어넣는 자체 캐릭터들과 다채로운 시청각 자료,{"\n"}   최고급 수준의 교구를 제공합니다.{"\n"}
            - 플레이송스 AI시스템으로 맞춤형 프로그램과 교구재를 추천하고 있습니다.{"\n"}
            - 수업촬영 사진을 키즈노트에 업로드해 드립니다.
          </span>
        </CurriculumInfoDescription>
      </CurriculumInfoZone>
    </>
  );
}

function DesktopCurriculumInfoView() {
  return (
    <>
      <CurriculumInfoZone>
        <CurriculumInfoSection>
          <CurriculumInfoTitle>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
              width={"100%"}
              alt=""
            />
            <span>몰입하고 성취하는 아이들​</span>
          </CurriculumInfoTitle>
          <CurriculumInfoDescription>
            <span>
              아름다운 음악과 신기한 이야기를 중심으로 경험하는 긴밀하고 격렬한
              상호작용은 감성을 깊숙이 자극하고 아이들을 몰입하게 합니다. 발달
              단계별 발달과업을 정밀하게 다룬 프로그램은 아이가 모든 활동에
              스스로 참여하고 성취할 수 있게 합니다.
            </span>
          </CurriculumInfoDescription>
        </CurriculumInfoSection>
        <CurriculumInfoImg>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/curriculum/pc-curriculum-1%403x.png"
            alt=""
            width={"100%"}
          />
        </CurriculumInfoImg>
      </CurriculumInfoZone>

      <CurriculumInfoZone>
        <CurriculumInfoSection>
          <CurriculumInfoTitle>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
              width={"100%"}
              alt=""
            />
            <span>소수정예 그룹, 2명의 선생님</span>
          </CurriculumInfoTitle>
          <CurriculumInfoDescription>
            <span>
              1회 40분 수업으로 2명의 교사와 5명의 아이들이 함께합니다. 선생님이
              직접 연주하고 노래하며 아이 한 명 한 명과 교감할 수 있도록
              구성되었습니다.
            </span>
          </CurriculumInfoDescription>
        </CurriculumInfoSection>
        <CurriculumInfoImg>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/curriculum/pc-curriculum-2%403x.png"
            alt=""
            width={"100%"}
          />
        </CurriculumInfoImg>
      </CurriculumInfoZone>

      <CurriculumInfoZone>
        <CurriculumInfoSection>
          <CurriculumInfoTitle>
            <img
              src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
              width={"100%"}
              alt=""
            />
            <span>국내 유일의 전문 교사</span>
          </CurriculumInfoTitle>
          <CurriculumInfoDescription>
            <span>
              플레이송스의 모든 교사들은 MASTER MUSIC AND PLAY PRACTITIONER을
              이수하고 테스트에 합격하여 MMP자격증을 보유하고 있습니다. 또한
              지속적인 Self-critic과 엄격한 Supervising System을 통해 전문성과
              역량을 최고 수준으로 유지합니다.
            </span>
          </CurriculumInfoDescription>
        </CurriculumInfoSection>
        <CurriculumInfoImg>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/curriculum/mmp-1%403x.png"
            alt=""
            width={"100%"}
          />
        </CurriculumInfoImg>
      </CurriculumInfoZone>

      <CurriculumInfoZone style={{ flexDirection: "column" }}>
        <CurriculumInfoTitle>
          <img
            src="https://playsongshome.playsongs.ai/playsongs/aboutus/note.png"
            width={"100%"}
            alt=""
          />
          <span>차별화된 서비스</span>
        </CurriculumInfoTitle>
        <CurriculumInfoDescription className="text_wrap">
          <span>
            - 친근감과 생동감을 불어넣는 자체 캐릭터들과 다채로운 시청각 자료,
            최고급 수준의 교구를 제공합니다.{"\n"}- 플레이송스 AI시스템으로
            맞춤형 프로그램과 교구재를 추천하고 있습니다.{"\n"}- 수업 촬영
            사진을 키즈노트에 업로드해 드립니다.
          </span>
        </CurriculumInfoDescription>
      </CurriculumInfoZone>
    </>
  );
}

function StepInfoView() {
  return (
    <>
      <TitelView className="text_wrap">
        <Desktop>플레이송스의 체계적인 커리큘럼</Desktop>
        <Tablet>플레이송스의 체계적인 커리큘럼</Tablet>
        <Mobile>플레이송스의{"\n"}체계적인 커리큘럼</Mobile>
      </TitelView>
      <StepTitleSection>
        <StepTitleView>
          <span className="playsongs-curriculum-span-step-title">
            <span className="text">STEP 1{"\r"}</span>
            (9~12개월)
          </span>
        </StepTitleView>
        <StepDescriptView>
          <span className="playsongs-curriculum-span-step-script">
            천재 음악가
          </span>
        </StepDescriptView>
      </StepTitleSection>
      <SetDescriptSetion className="text_wrap">
        <span>
          기거나 걷기 시작하면서 활동 영역과 시야가 확장되어가는 시기입니다.
          {"\n"}
          오감을 통해 나와 주변을 탐색할 수 있도록 굴리기, 던지기, 뽑기, 잼잼,
          곤지곤지, 짝짜꿍 등 의 놀이 수업을 통해 주 양육자와의 긍정적 애착을
          발달시킵니다.
        </span>
      </SetDescriptSetion>

      <StepTitleSection style={{ backgroundColor: "#4eb2f1" }}>
        <StepTitleView>
          <span className="playsongs-curriculum-span-step-title">
            <span className="text">STEP 2{"\r"}</span>
            (12~18개월)
          </span>
        </StepTitleView>
        <StepDescriptView className="text_wrap">
          <span className="playsongs-curriculum-span-step-script">
            <Desktop>세상 만나기 / 나도나도</Desktop>
            <Tablet>세상 만나기 / 나도나도</Tablet>
            <Mobile>세상 만나기{"\n"}나도나도</Mobile>
          </span>
        </StepDescriptView>
      </StepTitleSection>
      <SetDescriptSetion className="text_wrap">
        <span>
          호기심이 증진되고 무엇이든 스스로 해보려는 욕구가 강해지는 시기입니다.
          {"\n"}
          ‘우리집’ ‘엄마, 아빠‘ 와 같은 주제들을 통해 호기심을 자극하고
          돌려보고, 쌓아보고, 넣어보는 등의 놀이 수업을 통해 인지, 감각, 조작,
          탐색 능력을 증진시키고 신체 조절 능력을 발달시킵니다​.
        </span>
      </SetDescriptSetion>

      <StepTitleSection style={{ backgroundColor: "#f96c9c" }}>
        <StepTitleView>
          <span className="playsongs-curriculum-span-step-title">
            <span className="text">STEP 3{"\r"}</span>
            (18~24개월)
          </span>
        </StepTitleView>
        <StepDescriptView className="text_wrap">
          <span className="playsongs-curriculum-span-step-script">
            <Desktop>놀기대장 / 오늘도 즐거워</Desktop>
            <Tablet>놀기대장 / 오늘도 즐거워</Tablet>
            <Mobile>놀기대장{"\n"}오늘도 즐거워</Mobile>
          </span>
        </StepDescriptView>
      </StepTitleSection>
      <SetDescriptSetion className="text_wrap">
        <span>
          어휘력이 급증하고 기억력이 향상되며 모방 행동이 구체화되는 시기입니다.
          {"\n"}
          동물, 탈것 등을 모방하는 놀이로 신체 협응 능력을 기르고 ‘소풍 가는
          날‘,‘ 캠핑 가는 날’ 등 일상의 즐거운 소재로 어휘를 확장하고 인지,
          신체, 사회성 등 여러 가지 영역을 고르게 발달시킵니다.
        </span>
      </SetDescriptSetion>

      <StepTitleSection style={{ backgroundColor: "#f17b52" }}>
        <StepTitleView>
          <span className="playsongs-curriculum-span-step-title">
            <span className="text">STEP 4{"\r"}</span>
            (24~30개월)
          </span>
        </StepTitleView>
        <StepDescriptView className="text_wrap">
          <span className="playsongs-curriculum-span-step-script">
            <Desktop>신나는 모험 / 테마극장</Desktop>
            <Tablet>신나는 모험 / 테마극장</Tablet>
            <Mobile>신나는 모험{"\n"}테마극장</Mobile>
          </span>
        </StepDescriptView>
      </StepTitleSection>
      <SetDescriptSetion className="text_wrap">
        <span>
          인지적 능력이 크게 향상되며 상징적 사고가 발달하는 시기입니다.{"\n"}
          ‘이탈리아’ ‘인도’ 등 다른 나라의 문화, 예술을 놀이로 경험하며 시야와
          개념을 확장하고 소방관, 미용사 등 역할놀이를 통해 공감 능력,
          자기조절력과 사회성을 발달시킵니다.
        </span>
      </SetDescriptSetion>

      <StepTitleSection style={{ backgroundColor: "#fbb033" }}>
        <StepTitleView>
          <span className="playsongs-curriculum-span-step-title">
            <span className="text">STEP 5{"\r"}</span>
            (30개월~)
          </span>
        </StepTitleView>
        <StepDescriptView className="text_wrap">
          <span className="playsongs-curriculum-span-step-script">
            <Desktop>음악극(체험)​ / 음악극(융합)</Desktop>
            <Tablet>음악극(체험)​ / 음악극(융합)</Tablet>
            <Mobile>음악극(체험)​{"\n"}음악극(융합)</Mobile>
          </span>
        </StepDescriptView>
      </StepTitleSection>
      <SetDescriptSetion className="text_wrap">
        <span>
          이야기를 이해하고 친구들과 협동하여 놀이할 수 있는 시기입니다.{"\n"}
          발달 수준에 맞는 다양한 이야기를 모티브로 음악, 동작, 미술 활동을 통해
          이야기속 사건을 직접 체험함으로써 상상력과 창의력, 공감 능력을 기르고
          다양한 의사소통 방법을 경험합니다.
        </span>
      </SetDescriptSetion>
    </>
  );
}
