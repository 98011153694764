import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Root from "./Root";
import NotFound from "./Componets/NotFound";
import Bicycle from "./Stage/Bicycle";
import Playsongs from "./Stage/Playsongs";
import PSchedule from "./screens/Playsongs/PSchedule";
import AboutUs from "./screens/Playsongs/AboutUs";
import Curriculum from "./screens/Playsongs/PCurriculum";
import ContactUs from "./screens/Playsongs/ContactUs";
import FamilySite from "./screens/Playsongs/FamilySite";
import { WorkWithUs } from "./screens/Playsongs/WorkWithUs";
import PEnglishClub from "./screens/Playsongs/PEnglishClub";
import ApplyForm from "./screens/Playsongs/ApplyForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Playsongs />,
        errorElement: <NotFound />,
      },
      {
        path: "aboutus",
        element: <AboutUs />,
      },
      {
        path: "curriculum",
        element: <Curriculum />,
      },
      {
        path: "PEnglishClub",
        element: <PEnglishClub />,
      },
      {
        path: "schedule",
        element: <PSchedule />,
      },
      {
        path: "/FamilySite",
        element: <FamilySite />,
      },
      {
        path: "Admission",
        //element: <Admission />,
      },

      {
        path: "ContactUs",
        element: <ContactUs isAp={true} />,
      },
      {
        path: "WorkWithUs",
        element: <WorkWithUs />,
      },
      {
        path: "bicycle",
        element: <Bicycle />,
      },
    ],
    errorElement: <NotFound />,
  },
]);
export default router;
